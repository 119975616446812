* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 14pt;
  font-family: sans-serif;
}

h1 {
  font-weight: 500;
}
